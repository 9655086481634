@import "assets/fonts/fonts";
.import-art-wrapper {
    background-color: white;
    padding: 25px;
    border-radius: 5px;
    .file-card--wrapper {
        max-width: 100%;
        margin-inline: auto;
        margin-bottom: 24px;
        border: 1px dashed #a1a5b7b3;
        border-radius: 4px;
        padding: 20px;
        .art-uploader-heading {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #5a5a5a;
        }
    }
}

//artwork
a.nav-link.artwork-tab {
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    &:active {
        color: #181c32 !important;
        border-radius: 0px !important;
        border-color: #f1faff !important;
        border-bottom: 2px solid black !important;
        background-color: white !important;
        background-color: unset;
    }
    &.active {
        color: #181c32 !important;
        border-radius: 0px !important;
        border-color: #f1faff !important;
        border-bottom: 2px solid black !important;
        background-color: white !important;
        background-color: unset;
    }
    &:hover {
        color: #181c32 !important;
        border-radius: 0px !important;
        border-color: #f1faff !important;
        border-bottom: 2px solid black !important;
        background-color: white !important;
    }
}

#kt_activities_footer {
    bottom: 0px;
    position: fixed;
}

.dropdown-toggle::after {
    display: none !important;
}

.user-img.drop-down-sec.show.dropdown {
    >.btn {
        background-color: red;
    }
}

tbody {
    border-top: 1px solid #f4f7f9 !important;
}

.notification {
    font-size: 12px;
    line-height: 14px;
    padding: 2px 6px;
    background-color: red;
    color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: 3px;
}

.icon-lg {
    svg {
        height: 20px;
        width: 18px;
    }
}

span.art-action {
    button.dropdown-toggle.btn {
        all: unset !important;
    }
    .drop-down-sec.dropdown {
        padding: 0px 2px 8px 2px;
        cursor: pointer;
        SVG {
            stroke: #98a2b3;
            font-size: 14px;
        }
    }
}

a.card-icons.dropdown-item {
    padding: 7px 35px 6px 13px !important;
    &:active {
        background-color: #ffffff;
        &:hover {
            background-color: #ffffff;
        }
    }
}

a.card-icons.dropdown-item:last-child {
    color: #b42318 !important;
    border-top: 1px solid #a8a1a12b;
    SVG {
        stroke: #b42318;
    }
    p {
        color: #b42318 !important;
    }
    &:active {
        background-color: #b42318 !important;
    }
    &:hover {
        background-color: #f1416c12 !important;
    }
    .symbol.symbol-45px>img {
        border-radius: 50% !important;
    }
}

.select-two {
    margin-block: 15px;
    .filter--title {
        font-weight: 500 !important;
    }
}

.blocked-list {
    padding-top: 15px;
    margin-bottom: 32px;
    .filter--title {
        text-transform: uppercase;
    }
    ul {
        padding: 0;
        margin-top: 15px;
        li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            label.form-check {
                margin-right: 0 !important;
            }
            span {
                line-height: 1;
                height: 5px;
            }
        }
    }
}

.react-lightbox-image {
    float: left;
    width: 50px;
    height: 50px;
    background-size: cover;
    margin: 10px;
    cursor: pointer;
}

.react-lightbox-overlay {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient( ellipse at center, rgba(25, 25, 25, 0.5) 0%, rgba(25, 25, 25, 0.25) 100%);
    transition: opacity 0.5s ease-in-out;
}

.react-lightbox-overlay-open {
    opacity: 1;
}

.react-lightbox-carousel-image {
    position: absolute;
    border-radius: 50%;
    width: 400px;
    height: 400px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-size: cover;
    transition: transform 0.25s ease-in, opacity 0.25s ease-in;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 40px rgba(25, 25, 25, 0.5);
}

.react-lightbox-carousel-image-backward {
    transform: translate3d(-400px, 0, 0);
    opacity: 0;
}

.react-lightbox-carousel-image-forward {
    transform: translate3d(400px, 0, 0);
    opacity: 0;
}

.my-controls {
    position: absolute;
    width: 600px;
    height: 100px;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

.my-button {
    font-size: 24px;
    background-color: #eaeaea;
    border-radius: 50%;
    width: 50px;
    line-height: 50px;
    height: 50px;
    color: #333;
    cursor: pointer;
    font-weight: bold;
    font-family: "Arial Black";
    opacity: 0.8;
}

.my-button:hover {
    background-color: #fff;
}

.my-button-left {
    float: left;
}

.my-button-right {
    float: right;
}

.likes-icon {
    font-size: 30px;
}

.suggest-icon {
    font-size: 30px;
}

.white-space td {
    word-break: break-all;
    max-width: 300px;
    vertical-align: baseline;
}

.error-body {
    height: 100%;
    overflow: auto;
    max-height: 300px;
}

.imageUploaderWrapper {
    // height: 160px;
    position: relative;
}

.fileUpload {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: 50px;
    opacity: 0;
    cursor: pointer;
    // background-color: red !important;
}

.square-upload {
    overflow: hidden;
    width: 145px;
    height: 145px;
    border: 1px solid #3f425417;
    top: 72px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.circle {
    border-radius: 100% !important;
    overflow: hidden;
    width: 145px;
    height: 145px;
    border: 1px solid #3f425417;
    top: 72px;
    img {
        width: 100%;
        height: 100%;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.pImage {
    position: absolute;
    top: 112px;
    color: #666666;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    left: 110px;
    padding: 6px;
    border-radius: 25px;
    line-height: 0;
    box-shadow: 0px 4px 16px rgba(175, 185, 222, 0.3);
    background-color: white;
    cursor: pointer;
}

.crossImage {
    position: absolute;
    top: 0;
    color: #666666;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    left: 134px;
    cursor: pointer;
    border-radius: 25px;
    line-height: 0;
    box-shadow: 0px 4px 16px rgb(175 185 222 / 30%);
    background-color: white;
    border: 1px solid;
    /* right: 9px; */
    svg {
        width: 18px;
        height: 18px;
    }
}

.pImage:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.uploadButton {
    font-size: 1.2em;
    cursor: pointer;
}

.uploadButton:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    color: #999;
}

.calander-icon {
    background-image: url("./assets/Artwork/calander.svg") !important;
    background-repeat: no-repeat !important;
    background-position: left 15px center !important;
    padding-inline: 47px;
}

.fv-row {
    margin-bottom: 10px !important;
}

.border-block {
    border-block: 1px solid #d2cccc67 !important;
    padding-block: 20px !important;
}

//datepicker
.react-datepicker__day--selected {
    background-color: #181c32 !important;
}

.react-datepicker {
    box-shadow: 5px 4px 13px 6px #5e62781a !important;
}

.iconss {
    font-size: 18px;
    opacity: 0.2;
    cursor: pointer;
}

.icons-del {
    font-size: 18px;
    color: #5a5a5a;
    cursor: pointer;
}

.edit-del {
    font-size: 18px;
    color: #8a4d1c;
    cursor: pointer;
}

.article-cover {
    img {
        width: 100%;
        object-fit: contain;
        height: 300px;
        border-radius: 0.475rem !important;
        max-height: 100%;
    }
}

li.react-multi-carousel-item {
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    // max-height: 500px;
}

.react-multiple-carousel__arrow--right {
    right: calc(7% + 1px);
}

.react-multiple-carousel__arrow {
    min-width: 25px !important;
    min-height: 25px !important;
}

.react-multiple-carousel__left {
    min-width: 25px !important;
    min-height: 25px !important;
}

.react-multiple-carousel__arrow::before {
    font-size: 12px !important;
}

.w-120 {
    width: 120px;
}

.mb-32 {
    margin-bottom: 32px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.p-32 {
    padding: 32px !important;
}

.p-24 {
    padding: 24px !important;
}

.h-160 {
    height: 160px !important;
}

.btn-sms {
    height: 25px !important;
    width: 25px !important;
}

.table-data {
    color: #000000;
    font-size: 14px;
}

.coming-soon {
    position: relative;
    height: calc(100vh - 330px);
    h1 {
        font-size: 80px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #807d7d;
        height: 100%;
    }
}

.footer-button {
    max-width: 250px;
    justify-content: space-between;
}

li.breadcrumb-item a {
    color: #000000 !important;
    &:hover {
        color: #5a5a5a !important;
    }
}

.statscard-view {
    white-space: nowrap;
    border: 0px solid #eff2f5;
    background-color: #fff !important;
    border-radius: 0px !important;
    color: #b7bac5 !important;
    max-height: 100%;
    padding-block: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon {
        svg {
            font-size: 18px;
            fill: #bebec0;
        }
    }
    .counts {
        font-size: 14px;
    }
    // @media screen and (max-device-width: 768px) {
    //   width: 210px;
    //   max-width: 100%;
    // }
}

.artdetails-wrapper-card {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    display: flex;
}

.statscard-view.active-stats-tab {
    border-bottom: 4px solid #181c32;
    color: #181c32 !important;
}

.error-card.card {
    border: 1px solid rgb(207, 207, 207);
    .card-body {
        padding: 0px !important;
        .card-image img {
            max-height: 100%;
            max-width: 100%;
            border-bottom: 1px solid #eff2f5;
            width: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0px 0px;
            height: 150px;
        }
        .card-content {
            padding: 12px;
        }
    }
}

ul {
    padding: 0;
    margin-block: 20px;
    li {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    li:first-child {
        margin-right: 5px;
    }
}

.react-multi-carousel-track {
    height: 400px;
    li img {
        height: 100%;
        width: 400px;
        object-fit: contain;
    }
}

.child-icon-menu {
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
}

.dropdown-content {
    label.select-item {
        width: 100% !important;
    }
}

// MODAL FILTERS RIGHT
.modal.right {
    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 320px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        right: 0;
    }
    .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0px !important;
    }
    .modal-body {
        padding: 30px !important;
    }
}

.modal.right.fade.show {
    .modal-dialog {
        right: 0;
    }
}

.modal.right.fade {
    .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }
}

// label.dzu-inputLabel {
//   display: none !important;
// }
.ck.ck-content {
    height: 100% !important;
    min-height: 300px !important;
}

tr:hover {
    // background: #3f42540d !important;
    cursor: pointer;
}

.art-btn {
    span:first-child {
        display: inline-flex;
    }
}

ARTIST DETAIL PAGE ARTWORKS LISTING .stats-tables {
    .card {
        border-radius: 0;
        margin-bottom: 0 !important;
        .card-header {
            // background-color: transparent !important;
            padding: 0;
            margin-bottom: 0 !important;
        }
        .card-body {
            background-color: white;
        }
    }
    .art-work-card {
        margin-bottom: 20px !important;
        border-radius: 8px !important;
    }
    // .footer {
    //   background-color: #f5f8fa;
    // }
}

// .tab-pane {
//   padding-left: 20px;
//   padding-right: 20px;
// }
.line-height-1 {
    line-height: 1;
}

.bg-light-pink {
    background-color: #f7d9e3;
}

.bg-light-purple {
    background-color: #cbd4f4;
}

.bg-light-blue {
    background-color: #cbf0f4;
}

.breadcrumb .breadcrumb-item:after {
    content: " ";
    padding-left: 0.5rem;
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #808080;
    border-right: 2px solid #808080;
    transform: rotate(-45deg);
    right: 6px;
    margin-inline: 10px;
    top: 4px;
}

.react-multiple-carousel__arrow--left {
    left: calc(7% + 1px) !important;
}

// .article-desc {
//   max-width: 150px;
//   white-space: nowrap;
//   width: 100%;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
.box {
    width: 200px;
}

.modal-footer {
    justify-content: space-between !important;
}

.box span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    // max-width: clamp(200px, 34.5rem + 144vw, 100px);
    max-width: 85%;
    white-space: nowrap;
    width: 100%;
}

.border-dark {
    border-color: #e4e6ef !important;
}

.dashboard-kpi-tags {
    .select-items-tags {
        min-height: 0px;
        overflow: auto;
        width: 100%;
        font-size: 1.1rem;
        font-weight: 500;
        color: #181c32;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0.475rem;
        span {
            background: white;
            line-height: 18px;
            padding: 6px 12px;
            color: #3f4254;
            font-size: 14px;
            margin: 2px;
            border: 1px solid #d0d5dd;
            border-radius: 8px;
            display: inline-block;
            font-weight: 400;
        }
    }
}

body.modal-open {
    height: 100vh;
    overflow-y: hidden;
    padding-right: 0px;
    /* Avoid width reflow */
}

.rmsc .dropdown-container {
    border: 1px solid #e4e6ef !important;
    padding: 2px;
}

.custom-uploader {
    position: relative;
    .upload-icon {
        background: #f2f4f7;
        border: 6px solid #f9fafb;
        border-radius: 28px;
        padding: 5px;
        height: 40px;
        width: 40px;
        color: #3f4254b0;
        cursor: pointer;
    }
    .art-uploader-btn {
        position: absolute;
        right: 0;
        opacity: 0;
        top: 2px;
    }
}

.custom-uploader-image-wrapper {
    border: 1px dashed #e4e6ef;
    border-radius: 0.475rem;
    .selected-image-wrapper {
        width: 100%;
        display: inline-flex;
        flex-wrap: wrap;
        grid-gap: 10px;
        gap: 10px;
        padding: 20px;
        .image-wrapper {
            position: relative;
            width: 70px;
            height: 100px;
            border: 1px solid #e4e6ef;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .cancel-icon {
                position: absolute;
                position: absolute;
                font-size: 20px;
                right: -8px;
                top: -8px;
                cursor: pointer;
            }
        }
    }
}

.input-group-text {
    padding: 0px !important;
}

.select-bordered .form-select {
    border: none !important;
    background-color: #f5f8fa !important;
    border-radius: 0px !important;
}

.data-not-found {
    padding: 50px;
}

.client-Avatar {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    img {
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}

.kpi-cards {
    .artwork-image-Wrapper {
        width: 66px;
        height: 66px;
        object-fit: contain;
        margin-right: 20px;
        img {
            max-height: 100%;
            max-width: 100%;
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}

.categories-table {
    max-height: calc(100vh - 210px);
    overflow: auto;
    height: 100%;
}

.table-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin: 12px 12px 12px 0;
}

.card-body {
    .table-responsive {
        .footer {
            padding: 0px !important;
        }
    }
}

.ck.ck-content {
    max-height: 500px !important;
    overflow: auto;
}

.stats-tables {
    .card-body {
        padding: 0px !important;
    }
    .footer {
        padding: 0px !important;
    }
    .articles-cards {
        margin-bottom: 0px !important;
    }
}

.journal-cards {
    .card-body.j-c {
        padding: 0px !important;
    }
    .articles-cards {
        margin-bottom: 0px !important;
    }
}

.modal-filter {
    height: calc(100vh - 161px);
    overflow: auto;
}

.artcard {
    cursor: pointer;
    border: 2px solid #ededed85;
    .card-body {
        padding: 14px !important;
        .ArtimageWrapper {
            img {
                // padding: 14px;
                padding-bottom: 0px;
            }
        }
        .cardContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;
            .art-title {
                font-size: 16px;
                line-height: 1.2;
                color: #000000;
            }
            .art-action {
                opacity: 0;
                margin-left: auto;
            }
        }
    }
    &.selected {
        border: 2px solid rgb(0, 0, 0);
        .art-action {
            opacity: 1 !important;
        }
    }
}

.slider-pos {
    display: flex;
    flex-direction: column;
    max-width: 465px;
    margin-inline: auto;
    width: 100%;
    height: 100%;
    justify-content: center;
    .form-check-slider {
        display: flex;
        margin-top: 42px;
        label {
            margin-left: 6px;
        }
    }
}

.rc-slider {
    .rc-slider-rail {
        height: 8px;
    }
    .rc-slider-track {
        height: 8px;
        background-color: #000;
    }
    .rc-slider-step {
        height: 8px;
    }
    .rc-slider-handle {
        width: 24px;
        height: 24px;
        margin-top: -10px;
        border: solid 2px #000;
        background-color: #000;
    }
    .rc-slider-handle:hover {
        border-color: #000;
    }
}

.suggestArtworksCrad {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 20px;
}

ul.db-kpi.nav.nav-tabs {
    li.nav-item {
        border: 0;
        margin-right: 30px;
        font-size: 16px;
    }
    button {
        border: 0px;
        &.active {
            border: 0px;
            border-bottom: 2px solid black !important;
        }
        &:active {
            border: 0px;
            border-bottom: 2px solid black !important;
        }
    }
}

.center-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    .section:not(:first-child) {
        position: relative;
        // &::after {
        //   content: "";
        //   border: 0.5px solid #f5f5f5;
        //   position: absolute;
        //   height: 100%;
        //   top: 0;
        //   left: -70px;
        // }
    }
}

///stats
.statskpi-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    height: 100%;
    .upper-section {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .count {
            font-weight: bold;
            font-size: 34px;
            line-height: 38px;
            margin-bottom: 4px;
        }
        .title {
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            color: rgba(15, 44, 85, 0.6);
            font-weight: 500;
        }
    }
    &.totalsales {
        color: #fff;
        background-color: #485461;
        background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
        .title {
            color: #fff !important;
        }
        .bottom {
            .count {
                color: #fff !important;
            }
        }
    }
}

// .bottom-cards {
//   .card {
//     height: 100% !important;
//   }
// }
.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    margin-bottom: 32px;
}

.kpi-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    margin-bottom: 32px;
    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(350px, 1fr));
    }
    @media (min-width: 1680px) {
        grid-template-columns: repeat(3, minmax(350px, 1fr));
    }
}

.three-icons {
    justify-self: flex-end;
    display: flex;
    width: 100%;
    text-align: right;
    gap: 10px;
    flex: 1;
    justify-content: flex-end !important;
}

.form-check-input:checked {
    background-color: #000000 !important;
    border-color: #000000 !important;
}

.rc-slider-dot-active {
    border-color: #222c30 !important;
}

.rc-slider .rc-slider-handle {
    width: 15px !important;
    height: 15px !important;
    margin-top: -4px !important;
}

.manual-suggest-card-font-sm {
    font-size: 14px !important;
}

.manual-suggest-card-font-md {
    font-size: 16px !important;
}

.select-artwork-container {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    .suggestArtworksCrad {
        margin: 18px;
    }
    .artworkIntCards {
        margin: 18px;
        img {
            padding-bottom: 0px;
            width: 100%;
            height: 200px;
        }
    }
    i {
        font-style: italic;
        font-size: unset;
        color: unset;
    }
    .artcard .card-body .ArtimageWrapper img {
        padding-bottom: 0px;
        width: 100%;
        height: 200px;
    }
}