@font-face {
  font-family: "Gotham";
  src: url("Gotham-Black.eot");
  src: url("Gotham-Black.eot?#iefix") format("embedded-opentype"),
    url("Gotham-Black.woff2") format("woff2"),
    url("Gotham-Black.woff") format("woff"),
    url("Gotham-Black.ttf") format("truetype"),
    url("Gotham-Black.svg#Gotham-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Bold.eot");
  src: url("Gotham-Bold.eot?#iefix") format("embedded-opentype"),
    url("Gotham-Bold.woff2") format("woff2"),
    url("Gotham-Bold.woff") format("woff"),
    url("Gotham-Bold.ttf") format("truetype"),
    url("Gotham-Bold.svg#Gotham-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Book.eot");
  src: url("Gotham-Book.eot?#iefix") format("embedded-opentype"),
    url("Gotham-Book.woff2") format("woff2"),
    url("Gotham-Book.woff") format("woff"),
    url("Gotham-Book.ttf") format("truetype"),
    url("Gotham-Book.svg#Gotham-Book") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Medium.eot");
  src: url("Gotham-Medium.eot?#iefix") format("embedded-opentype"),
    url("Gotham-Medium.woff2") format("woff2"),
    url("Gotham-Medium.woff") format("woff"),
    url("Gotham-Medium.ttf") format("truetype"),
    url("Gotham-Medium.svg#Gotham-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Ultra";
  src: url("Gotham-Ultra.eot");
  src: url("Gotham-Ultra.eot?#iefix") format("embedded-opentype"),
    url("Gotham-Ultra.woff2") format("woff2"),
    url("Gotham-Ultra.woff") format("woff"),
    url("Gotham-Ultra.ttf") format("truetype"),
    url("Gotham-Ultra.svg#Gotham-Ultra") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Thin.eot");
  src: url("Gotham-Thin.eot?#iefix") format("embedded-opentype"),
    url("Gotham-Thin.woff2") format("woff2"),
    url("Gotham-Thin.woff") format("woff"),
    url("Gotham-Thin.ttf") format("truetype"),
    url("Gotham-Thin.svg#Gotham-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Light.eot");
  src: url("Gotham-Light.eot?#iefix") format("embedded-opentype"),
    url("Gotham-Light.woff2") format("woff2"),
    url("Gotham-Light.woff") format("woff"),
    url("Gotham-Light.ttf") format("truetype"),
    url("Gotham-Light.svg#Gotham-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-XLight.eot");
  src: url("Gotham-XLight.eot?#iefix") format("embedded-opentype"),
    url("Gotham-XLight.woff2") format("woff2"),
    url("Gotham-XLight.woff") format("woff"),
    url("Gotham-XLight.ttf") format("truetype"),
    url("Gotham-XLight.svg#Gotham-XLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
