.art-work-card.card {
  border-radius: 8px;
  .card-body {
    padding: 0px !important;
    .card-image {
      border-bottom: 1px solid #d0d5dd;
      height: 335px;
      img {
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 8px 8px 0px 0px;
      }
    }
    .card-content {
      padding: 12px;
      span.art-title {
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        font-weight: 500;
      }
      span.art-cost {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: #5a5a5a;
      }
    }
  }
}
