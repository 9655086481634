.artictle-listing {
  height: 370px;
  .article-title {
    font-size: 14px;
    line-height: 20px;

    color: #000000;
  }
  .article-description {
    font-size: 16px;
    color: #000000;
    margin-left: 5px;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.card.articles-cards {
  background: #f5f8fa !important;
}
.article-card.card {
  border-radius: 8px;
  .card-body {
    padding: 0px !important;
    .card-image img {
      max-height: 100%;
      max-width: 100%;
      border-bottom: 1px solid #eff2f5;
      width: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0px 0px;
      height: 241px;
    }
    .card-content {
      padding: 12px;
      span.art-title {
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        font-weight: 500;
      }
      span.art-cost {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        color: #5a5a5a;
      }
    }
  }
}
