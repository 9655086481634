.item-card-wrapper {
  padding: 4px;
  .item-card {
    background-color: #c4c4c4;
    border-radius: 10px;
    border: 1px solid #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 202px;
  }
  h2 {
    color: var(--white-text);
  }
  .item-card-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
  }
  .item-name {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: var(--black-text);
  }
  .item-type {
    font-size: 14px;
    line-height: 19px;
    color: var(--black-text);
  }
}
