.search-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    position: absolute;
    right: 0px;
    line-height: 1;
  }
  input {
    padding-block: 9px;
    border: 1px solid #e4e6ef;
    border-radius: 0.475rem;
    padding-right: 46px;
    padding-left: 12px;
    height: 44px;
    background: #f5f8fa;
    line-height: 1;
    &:focus-visible,
    &:focus {
      outline: none;
      border-color: rgb(92, 91, 91);
    }
  }
}
