.dzu-dropzone {
  display: flex;
  // flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  // overflow: scroll;
  margin: 0 auto;
  position: relative;
  // box-sizing: border-box;
  // transition: all 0.15s linear;
  // border: 1px dashed #e4e7ec;
  // border-radius: 8px;
  // padding: 16px;
}

.dzu-dropzoneActive {
  background-color: #deebff;
  border-color: #2484ff;
}

.dzu-dropzoneDisabled {
  opacity: 0.5;
}

.dzu-dropzoneDisabled *:hover {
  cursor: unset;
}

.dzu-input {
  display: none;
}

.dzu-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #181c32;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  .upload-icon {
    background: #f2f4f7;
    border: 6px solid #f9fafb;
    border-radius: 28px;
    padding: 5px;
    height: 40px;
    width: 40px;
    color: #3f4254b0;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0px !important;
    max-width: 208px;
    span {
      color: #667085 !important;
    }
  }
}

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0 14px;
  min-height: 32px;
  background-color: #e6e6e6;
  color: #181c32;
  border: none;
  font-family: 'Helvetica', sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 3%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-previewContainer {
  padding: 40px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  // border-bottom: 1px dashed #ececec;
  box-sizing: border-box;
}

.dzu-previewStatusContainer {
  display: flex;
  align-items: center;
}

.dzu-previewFileName {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.dzu-previewImage {
  width: auto;
  max-height: 40px;
  max-width: 140px;
  border-radius: 4px;
}

.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}

.dzu-submitButtonContainer {
  margin: 24px 0;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.dzu-submitButton {
  width: 100%;
  padding: 0 14px;
  min-height: 32px;
  background-color: #181c32;
  border: none;
  border-radius: 4px;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-submitButton:disabled {
  background-color: #e6e6e6;
  color: #333333;
  cursor: unset;
}
