.artdetails-wrapper-card {
  max-width: 100%;
  overflow: auto;
  .statscard {
    max-height: 100%;
    height: 120px;
    box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 12%);
    .counts {
      font-size: 48px;
      margin-bottom: 0px !important;
    }
  }
}
.art-gallery {
  .image-conatiner {
    img {
      max-height: 100%;
      max-width: 100%;
      width: 250px;
      object-fit: contain;
      border-radius: 8px 8px 0px 0px;
      height: 250px;
    }
  }
}
.select-items-view--wrapper {
  background-clip: padding-box;
  border-radius: 0.475rem;
  background-color: #fcfcfd;
  padding-block: 20px;
  border-radius: 2px;

  .select-items-view {
    // height: 100%;
    min-height: 0px;
    overflow: auto;
    display: block;
    max-height: 150px;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    color: #181c32;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.475rem;
    span {
      background: white;
      border-radius: 2px;
      padding: 6px 16px;
      color: #3f4254;
      font-size: 12px;
      margin: 2px;
      border: 1px solid #d0d5dd;
      border-radius: 4px;
      display: inline-block;
    }
  }
}
