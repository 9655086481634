.ModalWrapper {
  padding: 24px;
  :global {
    .modal-content {
      max-width: 400px;
      width: 100%;
    }
  }
  .content {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // padding: 33px;
    span {
      background: #fee4e2;
      border: 8px solid #fef3f2;
      border-radius: 100%;
      padding: 8px;
      line-height: 1;
      svg {
        line-height: 1;
      }
    }
    h2 {
      font-size: 18px;
      font-family: var(--Gotham-medium);
      color: var(--black);
      line-height: 28px;
      margin-bottom: 8px;
    }

    p {
      font-family: var(--art-grey);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 12px;
  }
}
